import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const Dashboard = () => import("@/views/dashboards/Default.vue");
const SignInBasic = () => import("@/views/auth/signin/Basic.vue");
const Error403 = () => import("@/views/auth/error/Error403.vue");
const Error404 = () => import("@/views/auth/error/Error404.vue");
const Error500 = () => import("@/views/auth/error/Error500.vue");
const UserList = () => import("@/views/pages/Config/Users/List.vue");
const EditUser = () => import("@/views/pages/Config/Users/Edit.vue");
const addNewUser = () => import("@/views/pages/Config/Users/New.vue");
const UserRoles = () => import("@/views/pages/Config/Roles/List.vue");
const EditRole = () => import("@/views/pages/Config/Roles/Edit.vue");
const NewRole = () => import("@/views/pages/Config/Roles/New.vue");
const PermissionsList = () =>
  import("@/views/pages/Config/Permissions/List.vue");
const EditPermission = () =>
  import("@/views/pages/Config/Permissions/Edit.vue");
const NewPermission = () => import("@/views/pages/Config/Permissions/New.vue");
const CompanyList = () => import("@/views/pages/Companies/List.vue");
const EditCompany = () => import("@/views/pages/Companies/EditCompany.vue");
const NewCompany = () => import("@/views/pages/Companies/New.vue");
const CompanyProfile = () => import("@/views/pages/Companies/Profile.vue");
const CompanyAddress = () => import("@/views/pages/Companies/AddressForm.vue");
const InviteEmployee = () =>
  import("@/views/pages/Companies/InviteEmployee.vue");
const RecipesList = () => import("@/views/pages/Config/Recipes/List.vue");
const EditRecipe = () => import("@/views/pages/Config/Recipes/Edit.vue");
const AllergensList = () => import("@/views/pages/Config/Allergens/List.vue");
const RecipeTypeList = () => import("@/views/pages/Config/RecipeType/List.vue");
const EditRecipeType = () => import("@/views/pages/Config/RecipeType/Edit.vue");
const NewRecipeType = () => import("@/views/pages/Config/RecipeType/New.vue");
const OrdersList = () => import("@/views/pages/Orders/List.vue");
const OrderDetail = () => import("@/views/pages/Orders/View.vue");
const ClientsList = () => import("@/views/pages/Clients/Client/List.vue");
const EditClient = () => import("@/views/pages/Clients/Client/Edit.vue");
const ClientProfile = () => import("@/views/pages/Clients/Client/Profile.vue");
const InvoicesList = () => import("@/views/pages/Invoices/List.vue");
const Invoice = () => import("@/views/pages/Invoices/Invoice.vue");
const ProteinsList = () => import("@/views/pages/Config/Proteins/List.vue");
const BagMovementsList = () =>
  import("@/views/pages/Logistics/BagMovements/List.vue");
const ViewBagMovement = () =>
  import("@/views/pages/Logistics/BagMovements/Details.vue");
const BagMovementCheckin = () =>
  import("@/views/pages/Logistics/BagMovements/BagCheckin.vue");
const BagMovementCheckout = () =>
  import("@/views/pages/Logistics/BagMovements/BagCheckout.vue");
const OrderDeliveriesList = () =>
  import("@/views/pages/Logistics/OrderDeliveries/List.vue");
const TDO = () => import("@/views/pages/Operations/TDO.vue");
const PackingList = () => import("@/views/pages/Operations/PackingList.vue");
const DeliverySchedule = () =>
  import("@/views/pages/Operations/DeliverySchedule.vue");
const Labels = () => import("@/views/pages/Operations/Labels.vue");
const FoodLabel = () => import("@/views/pages/Operations/FoodLabel.vue");
const DeliverySummary = () =>
  import("@/views/pages/Operations/DeliverySummary.vue");
const DeliveryNotes = () =>
  import("@/views/pages/Operations/DeliveryNotes.vue");
const RefundRequests = () =>
  import("@/views/pages/RefundRequests/RefundRequestsList.vue");
const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/users",
    name: "Users List",
    component: UserList,
  },
  {
    path: "/users/new",
    name: "Add New User",
    component: addNewUser,
  },
  {
    path: "/users/:id",
    name: "Edit User",
    component: EditUser,
  },
  {
    path: "/users/roles",
    name: "User Roles List",
    component: UserRoles,
  },
  {
    path: "/users/roles/new",
    name: "Add New Role",
    component: NewRole,
  },
  {
    path: "/users/roles/:id",
    name: "Edit Role",
    component: EditRole,
  },
  {
    path: "/users/roles/permissions",
    name: "Permissions List",
    component: PermissionsList,
  },
  {
    path: "/users/roles/permissions/new",
    name: "Add New Permission",
    component: NewPermission,
  },
  {
    path: "/users/roles/permissions/:id",
    name: "Edit Permission",
    component: EditPermission,
  },
  {
    path: "/companies",
    name: "Companies List",
    component: CompanyList,
  },
  {
    path: "/companies/:companyId/address/:addressId",
    name: "Company Address",
    component: CompanyAddress,
  },
  {
    path: "/companies/invite-employee/:id",
    name: "Invite Employee",
    component: InviteEmployee,
  },
  {
    path: "/companies/:id",
    name: "Edit Company",
    component: EditCompany,
  },
  {
    path: "/companies/new",
    name: "New Company",
    component: NewCompany,
  },
  {
    path: "/companies/:id/profile",
    name: "Company Profile",
    component: CompanyProfile,
  },

  {
    path: "/signin",
    name: "SignIn",
    component: SignInBasic,
  },
  {
    path: "/error403",
    name: "Error Error403",
    component: Error403,
  },
  {
    path: "/error404",
    name: "Error Error404",
    component: Error404,
  },
  {
    path: "/error500",
    name: "Error Error500",
    component: Error500,
  },
  {
    path: "/config/recipes",
    name: "Recipes",
    component: RecipesList,
    meta: {
      title: "Recipes",
      auth: false,
      guard: {
        action: "view",
        subject: "recipes",
      },
    },
  },
  {
    path: "/config/recipes/:id",
    name: "Edit Recipe",
    component: EditRecipe,
    meta: {
      title: "Edit Recipe",
      auth: false,
      guard: {
        action: "update",
        subject: "recipes",
      },
    },
  },
  {
    path: "/config/new/recipe",
    name: "New Recipe",
    component: EditRecipe,
    meta: {
      title: "New Recipe",
      auth: false,
      guard: {
        action: "create",
        subject: "recipes",
      },
    },
  },
  {
    path: "/config/allergens",
    name: "Allergens",
    component: AllergensList,
    meta: {
      title: "Recipes",
      auth: false,
      guard: {
        action: "view",
        subject: "recipes",
      },
    },
  },
  {
    path: "/config/recipe-types",
    name: "Recipe Types",
    component: RecipeTypeList,
    meta: {
      title: "Recipes Types",
      auth: false,
      guard: {
        action: "view",
        subject: "Recipes Types",
      },
    },
  },
  {
    path: "/config/recipe-types/:id",
    name: "Edit Recipes Type",
    component: EditRecipeType,
    meta: {
      title: "Edit Recipes Type",
      auth: false,
      guard: {
        action: "update",
        subject: "recipes type",
      },
    },
  },
  {
    path: "/config/recipe-types/new",
    name: "New Recipes Type",
    component: NewRecipeType,
    meta: {
      title: "New Recipes Type",
      auth: false,
      guard: {
        action: "create",
        subject: "recipes type",
      },
    },
  },
  {
    path: "/config/protein",
    name: "Proteins",
    component: ProteinsList,
    meta: {
      title: "Proteins",
      auth: false,
      guard: {
        action: "view",
        subject: "proteins",
      },
    },
  },
  {
    path: "/logistics/bag-movements",
    name: "Bag Movements",
    component: BagMovementsList,
    meta: {
      title: "Bag Movements",
      auth: false,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },
  {
    path: "/logistics/bag-movements/:id",
    name: "View Bag Movement",
    component: ViewBagMovement,
    meta: {
      title: "View Bag Movement",
      auth: false,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },
  {
    path: "/logistics/bag-movements/checkin",
    name: "Bag Movement Check-in",
    component: BagMovementCheckin,
    meta: {
      title: "Bag Movement Check-in",
      auth: false,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },
  {
    path: "/logistics/bag-movements/checkout",
    name: "Bag Movement Check-out",
    component: BagMovementCheckout,
    meta: {
      title: "Bag Movement Check-out",
      auth: false,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },
  {
    path: "/logistics/deliveries",
    name: "Deliveries",
    component: OrderDeliveriesList,
    meta: {
      title: "Deliveries",
      auth: true,
    },
  },

  {
    path: "/orders",
    name: "Orders List",
    component: OrdersList,
    meta: {
      title: "Orders List",
      auth: false,
      guard: {
        action: "view",
        subject: "orders",
      },
    },
  },
  {
    path: "/orders/:id",
    name: "Order Details",
    component: OrderDetail,
    meta: {
      title: "Order Details",
      auth: false,
      guard: {
        action: "view",
        subject: "orders",
      },
    },
  },

  {
    path: "/clients",
    name: "Client List",
    component: ClientsList,
    meta: {
      title: "Client List",
      auth: false,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/:id",
    name: "Update Client",
    component: EditClient,
    meta: {
      title: "Update Client",
      auth: false,
      guard: {
        action: "update",
        subject: "Update Client",
      },
    },
  },
  {
    path: "/clients/:id/profile",
    name: "Client Profile",
    component: ClientProfile,
    meta: {
      title: "Client Profile",
      auth: false,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },

  {
    path: "/config/recipe-types",
    name: "Recipes Types",
    component: RecipeTypeList,
    meta: {
      title: "Recipes Types",
      auth: false,
      guard: {
        action: "view",
        subject: "Recipes Types",
      },
    },
  },
  {
    path: "/config/recipe-types/:id",
    name: "Edit Recipes Type",
    component: EditRecipeType,
    meta: {
      title: "Edit Recipes Type",
      auth: false,
      guard: {
        action: "update",
        subject: "recipes type",
      },
    },
  },
  {
    path: "/config/recipe-types/new",
    name: "New Recipes Type",
    component: NewRecipeType,
    meta: {
      title: "New Recipes Type",
      auth: false,
      guard: {
        action: "create",
        subject: "recipes type",
      },
    },
  },

  {
    path: "/invoices",
    name: "Invoices List",
    component: InvoicesList,
    meta: {
      title: "Invoices List",
      auth: false,
      guard: {
        action: "view",
        subject: "invoices",
      },
    },
  },
  {
    path: "/invoices/:id",
    name: "Invoice",
    component: Invoice,
    meta: {
      title: "Invoice",
      auth: false,
      guard: {
        action: "view",
        subject: "invoices",
      },
    },
  },
  {
    path: "/operations/tdo",
    name: "TDO",
    component: TDO,
    meta: {
      title: "TDO",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },
  {
    path: "/operations/packing-list",
    name: "Packing List",
    component: PackingList,
    meta: {
      title: "Packing List",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },
  {
    path: "/operations/delivery-schedule",
    name: "Delivery Schedule",
    component: DeliverySchedule,
    meta: {
      title: "Delivery Schedule",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },
  {
    path: "/operations/delivery-summary",
    name: "Delivery Summary",
    component: DeliverySummary,
    meta: {
      title: "Delivery Summary",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },
  {
    path: "/operations/delivery-notes",
    name: "Delivery Notes",
    component: DeliveryNotes,
    meta: {
      title: "Delivery Notes",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },

  {
    path: "/operations/labels",
    name: "Labels",
    component: Labels,
    meta: {
      title: "Labels",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },
  {
    path: "/operations/food-label",
    name: "Food Label",
    component: FoodLabel,
    meta: {
      title: "Food Label",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },
  {
    path: "/refund-requests",
    name: "Refund Requests",
    component: RefundRequests,
    meta: {
      title: "Refund Requests",
      auth: false,
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "Page not found",
    component: Error404,
    meta: {
      auth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  document.title =
    process.env.VUE_APP_TITLE + (to.meta.title ? " - " + to.meta.title : "");

  if (!store.state.auth.authenticated && to.name !== "SignIn" && to.meta.auth) {
    next({ name: "SignIn" });
  } else {
    next();
  }
});
export default router;